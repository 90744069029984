import React from 'react';
import './Icon.scss';

const Icon = ({ uri, name, width, invert, padding, textClass, text }) => {
  return(
    text ?
    <div className="icon">
      <img className="icon__img" src={`${uri}/img/icons/${name}.svg`} alt={name}
        style={ { width: width, padding: padding, filter: `invert(${invert})` } } />
      <span className={`icon__text ${textClass}`}>{text}</span>
    </div>
    :
    <img className="icon" src={`${uri}/img/icons/${name}.svg`} alt={name}
        style={ { width: width, padding: padding, filter: `invert(${invert})` } } />
  );
};

export default Icon;