import React, {useState} from 'react';
import axios from 'axios';
import { Page404, Navbar, Footer, Quiz, ShopList, Landing } from './components';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

const staticUri = process.env.REACT_APP_STATIC_URI || 'https://fasales.ru';
const apiUri = process.env.REACT_APP_API_URI || 'https://fasales.ru/api';


const landingMode = process.env.REACT_APP_LANDING_MODE;

const App = () => {

  const [mountedFooter, setMountedFooter] = useState(true)

  const history = useHistory()

  // const [isSubmitted, setSubmitted] = useState(localStorage.getItem('submitted') === '1' ? true : false);


  const handleFormSubmit = (memberData) => {
    history.push('/promo');
    axios.post(apiUri + '/member/add', { ...memberData, score: localStorage.getItem('score') })
      .then(() => localStorage.setItem('subbmitted', 1))
      .catch();
  }


  // useEffect(() => {
  //    console.log("mountedFooter",  mountedFooter)
  // }, [mountedFooter])


  return (
    <div className="App">
      <Navbar staticUri={staticUri}/>
      <div className="wrapper">
        <div className="wrapper__bg"></div>
        <Switch>
          <Redirect exact from="/" to={landingMode==='true' ? '/landing' : '/promo'}/>
          {/* <Route exact path="/" component={Home} /> */}
          <Route exact path="/quiz" render={(props) => <Quiz {...props} apiUri={apiUri} staticUri={staticUri} onSubmit={handleFormSubmit} />} />
          <Route path="/promo" component={() =><ShopList apiUri={apiUri} staticUri={staticUri} />} />
          <Route exact path="/landing" component={() => <Landing  staticUri={staticUri}/>} />
          <Route path="*" component={Page404} />
        </Switch>
      </div>
        <Footer staticUri={staticUri} isSet={mountedFooter} /> 
    </div>
  );
}

export default App;
