import React, {useState, useEffect} from 'react';
import axios from 'axios'
import Notifications, {notify} from 'react-notify-toast';
import {CSSTransition } from 'react-transition-group'
import { Link, useLocation, useParams, Redirect} from 'react-router-dom';
import Smooth from 'react-smooth';
import { SpinnerCompact } from 'components';
import {gtagEvent} from '../../../lib/gtag'
import './Coupon.scss';

const vkmeMessageLink = process.env.REACT_APP_VKME_MESSAGE || 'http://vk.me/fa_sales'
const vkMessageLink = process.env.REACT_APP_VK_MESSAGE || 'https://vk.com/im?media=&sel=-75677090'

export default ({ staticUri, apiUri, safari, liteAgent, isMobile}) => {

    const [promocode, setPromocode]= useState()
    const [clicked, setClicked] = useState(false)
    const [hovered, setHovered] = useState(false)
    const [hoverFirstTime, setHoverFirstTime] = useState(false)
    const [valid, setValid]= useState()

    let location = useLocation()
    let {name} = useParams()
    const key = 'coupon'

    const promoUri = apiUri + name

    useEffect(()=>{
        location.state = name
    },[location, name]) 



    useEffect(()=>{
        setHoverFirstTime(false)
    },[isMobile]) 


 
    useEffect(() => {     
        let mounted = true
        const getPromocode = (array, id) => {
            const value = array.filter(el => el._id === id).shift()
            value ? setValid(true) : setValid(false)
            return value
        }

        const urlParams = new URLSearchParams(location.search)
        const code = urlParams.get(key)
    
        axios.get(promoUri)
          .then(res => mounted && setPromocode(() => getPromocode(res.data.data.promo, code)))
          .catch(err => console.error(err))
      
        return () => mounted = false
    }, [promoUri, location])


    const openLink = link =>{
        window.open(link, "_self")
        setTimeout(()=>  safari && setClicked(false) , 1000) 
    }
  

    const trackClick = (e, category) => {

        const urlParams = new URLSearchParams(location.search)
        const code = urlParams.get(key)

        gtagEvent({
          clientWindow: window,
          action: 'fasales_coupon',
          category: category,
          label: `coupon ${code}`,
        })
      }


    const handleCode = async ({event, code, link }) => {

        const showSuccess = async() => notify.show('✨ Промокод скопирован', 'success', 1500)
        const showError = async() => notify.show('🤔 Что-то пошло не так', 'error', 2500)
        const close = async() => notify.hide()

        if(!clicked){
            event.preventDefault()
            setClicked(true)
            if (navigator.clipboard) {
                navigator.clipboard.writeText(code).then(() => {
                    showSuccess()
                    trackClick(event, 'copy coupon')
                    setTimeout(()=> openLink(link) , 1000) 
                }).catch(() => showError())
            }else{
                let input = document.body.appendChild(document.createElement("input"))
                input.value = code
                input.focus()
                input.select()
                try {
                    document.execCommand('copy')
                    input.parentNode.removeChild(input)
                    showSuccess()
                    trackClick(event, 'copy coupon')
                    setTimeout(()=> openLink(link), 1000) 
                } catch (err) {
                    input.parentNode.removeChild(input)
                    showError()
                }
            }
            close()
        }
    }

    
  
    if (!promocode) {
        return(
            <div className ="coupon" >    
                <SpinnerCompact />
            </div>
            )
    }

    return(       
        <Smooth from="0" to="1" attributeName="opacity" duration={500} easing="ease-in">
            <div className ="coupon">    
                <Notifications />    
                {valid
                    ? <div className="coupon__outer">
                               {!isMobile && 
                               <div className="coupon__outer-back">
                                    {!clicked 
                                    ? <Link to={location.pathname.replace('/code','')}>
                                        <img className="coupon__outer-back-btn" src={staticUri + '/img/cross.svg'}
                                            alt="Закрыть"/>
                                    </Link>
                                    : <img className="coupon__outer-back-btn" src={staticUri + '/img/cross.svg'}
                                       alt="Закрыть"/>}
                                 </div>
                            }
                        <div className="coupon__item">
                                {isMobile && 
                              <div className="coupon__item-top">

                             {!clicked 
                                ? <a href={vkmeMessageLink} target="_blank" rel="noopener noreferrer" onClick={e=>trackClick(e, 'coupon not work')}> 
                                   <div className="coupon__item-top-help">Не работает?</div>
                                 </a>
                                : <div className="coupon__item-top-help">Не работает?</div> 
                             }

                                    {!clicked 
                                        ? <Link to={location.pathname.replace('/code','')}>
                                            <div className="coupon__item-top-closebtn">Закрыть</div>
                                          </Link>
                                        : <div className="coupon__item-top-closebtn">Закрыть</div> }
                                </div>}
                            <div className="coupon__item-name">Промо «‎{name}»‎</div>
                            <div className="coupon__item-title">{promocode.title}</div>
                            <div className="coupon__item-promo"   onClick={e => handleCode({event: e, code: promocode.discount.code, link: promocode.link })}>
                                <div className="coupon__item-promo-link">
                                        Скопировать промокод
                                </div>
                                <div className="coupon__item-promo-code">{promocode.discount.code}</div>
                            </div>
                            {!clicked 
                                ? <a href={promocode.link} target="_blank" rel="noopener noreferrer" className="coupon__item-link" onClick={e=>trackClick(e, 'coupon open shop')}> 
                                    <div className="coupon__item-btn">В магазин</div>
                                 </a>
                                : <div  className="coupon__item-link">
                                    <div className="coupon__item-btn">В магазин</div>   
                                  </div>     
                            }
                        </div>


                        {!isMobile && <div className="coupon__help">


                            {!clicked 
                                ? <a href={vkMessageLink} target="_blank" rel="noopener noreferrer"  onClick={e=>trackClick(e, 'coupon not work')}> 
                                    <div className="coupon__help-label" 
                                        onMouseEnter={() => {
                                            setHovered(true) 
                                            setHoverFirstTime(true)}}
                                        onMouseLeave={() => setHovered(false)}>
                                        Промокод не работает (?) 
                                    </div>
                                  </a>   
                                : <div className="coupon__help-label">
                                    Промокод не работает (?) 
                                  </div>}
                                    
 
                            <CSSTransition  
                                in={hovered}
                                classNames={`coupon__help-message-anim${liteAgent?'-lite':''}`}
                                timeout={liteAgent===true ? 100 : 200}     
                            >
                            <>
                                {hoverFirstTime &&
                                <div className="coupon__help-message">
                                    Напишите нам, если промо-код <br/>
                                    не работает. Сбросим новый <br/> 
                                    в течение 20 минут.
                                </div>}
                            </>
                            </CSSTransition> 
                        </div>
                                
                        }         
                    </div>
                    :  valid === false && <Redirect exact to={location.pathname.replace('/code','')} />
                }
                
                {valid===true && isMobile &&
                <div className="coupon__message">
                    <div className="coupon__message-box" >
                        <span style={{
                          backgroundImage: `url(${staticUri}/img/message-box.svg)`
                         }}>    
                            Привет! Если промик <br/>
                            не работает или не подходит <br/> — нажми на кнопку выше и <br/>напиши нам в ЛС, вышлем <br/> новый в течение 20 минут.
                        </span> 
                    </div>
                    <div className="coupon__message-shrek">
                        <img className="coupon__message-shrek-img" src={staticUri + '/img/shrek.png'} alt="Shrek"/>
                    </div>
                </div>}

            </div>
        </Smooth> 
    ) 
}