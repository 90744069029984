const gtagEvent = ({ clientWindow, action, category, label, value }) => {
    clientWindow.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    })
 }

 const gtagPath = ({clientWindow, gaMeasurementId, title, path}) => {
    // clientWindow.gtag('config', gaMeasurementId, {
    //     page_title: title,
    //     page_path: path,
    // })
    clientWindow.gtag('event', 'page_view', {
      page_title: title,
      page_location: clientWindow.location.href,
      page_path: path,
      send_to: gaMeasurementId,
    })
 }

 


 export {gtagEvent, gtagPath}


