import React from 'react'
import Smooth from 'react-smooth'
import './SpinnerCompact.scss'

const SpinnerCompact = () => {
  return (
    <Smooth from="0" to="1" attributeName="opacity" duration={300} easing="ease-in" begin={350} >
      <div className="spinner-compact">
        <div className="spinner-compact__element"></div>
        <div className="spinner-compact__element"></div>
        <div className="spinner-compact__element"></div>
      </div>
    </Smooth>
  )
}

export default SpinnerCompact