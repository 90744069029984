import React from 'react';
import {
  TwitterShareButton,
  TelegramShareButton,
  VKShareButton,
  TwitterIcon,
  TelegramIcon,
  VKIcon,
} from "react-share";
import Smooth from 'react-smooth';
import './Result.scss';

const Result = ({ apiUri, staticUri, result, score, questionsCount }) => {
  const grade = () => {
    let g;
    let step = questionsCount / 4;
    // 10-12
    if(score > questionsCount - step) g = 3;
    // 7-9
    else if(score > questionsCount - step*2) g = 2;
    // 4-6
    else if(score > questionsCount - step*3) g = 1;
    // 0-3
    else if(score => questionsCount - step*4) g = 0;
    return g;
  };

  return (
    <div className="result">
      <Smooth from="0" to="1" attributeName="opacity" duration={350}>
        <div className="result__picture" style={{backgroundImage: `url(${staticUri+result.picture[grade()]})`}}></div>
      </Smooth>
      <h1 className="result__score">{score + '/' + questionsCount}</h1>
      <p className="result__text">{result.text[grade()]}</p>
      <div className="result__share">
        <VKShareButton className="result__share-btn" url={apiUri} noParse="true" title={result.text[grade()]} image={result.picture[grade()]}>
          <VKIcon size={32} borderRadius={14} />
        </VKShareButton>
        <TelegramShareButton className="result__share-btn" url={apiUri} title={result.text[grade()]}>
          <TelegramIcon size={32} borderRadius={14} />
        </TelegramShareButton>
        <TwitterShareButton className="result__share-btn" url={apiUri} hashtags={['fa_sales', 'fashion', 'sales']} title={result.text[grade()]}>
          <TwitterIcon size={32} borderRadius={14} />
        </TwitterShareButton>
      </div>
    </div>
  )
}

export default Result;