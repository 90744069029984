import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom'
import Smooth from 'react-smooth'
import LandingItem from './LandingItem'
import './Landing.scss';
import {gtagPath} from '../../lib/gtag'


const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-TTGQEJZZS9';
//'G-7YRY5QRP2C'


const Landing = ({staticUri}) => {

  const [open, setOpen] = useState()

  let location = useLocation()

  useEffect(() => {
    if (location.pathname!=="/"){
      gtagPath({
        clientWindow: window,
        gaMeasurementId: GA_MEASUREMENT_ID, 
        title: location.pathname, 
        path: location.pathname,
      })
    }
  }, [location])


  const handleChanging = id =>{
      setOpen(id)
  }

  return (
    <Smooth from="0" to="1" attributeName="opacity" duration={ 300 } easing="ease-in">
      <div id="landing" className="landing">
        <div className="frame">

              <div className="landing-box__hand">
                <img className="landing-box__hand-img" src={`${staticUri}/img/landing/hand.png`} alt='hand' />
              </div>
              
              <div className="landing-box__media">

                  <div className="landing-box__media-title">
                  Подпишитесь на наши социальные сети
                  </div>
                  <div className="landing-box__media-group">     
                    <LandingItem id={0} staticUri={staticUri} vk="https://vk.com/fa_sales" telegram="https://t.me/fa_sales" icon="man_group"title="Мужская группа"  mediaHandler={handleChanging} open={open}/>
                    <LandingItem id={1} staticUri={staticUri} vk="https://vk.com/fa_sales_w" telegram="https://t.me/fa_sales_w" icon="woman_group"title="Женская группа" mediaHandler={handleChanging} open={open}/>
                    <LandingItem id={2} staticUri={staticUri} vk="https://vk.com/fa_perfumes" telegram="https://t.me/fa_perfumes" icon="perfume_sales"title="Скидки на парфюмы" mediaHandler={handleChanging} open={open}/>
                    <LandingItem id={3} staticUri={staticUri} vk="https://vk.com/fa_makeup" telegram="https://t.me/fa_makeup" icon="cosmetics_sales"title="Скидки на косметику" mediaHandler={handleChanging} open={open}/>
                  </div>
              </div>
          </div>
        </div>
      </Smooth>
  )
}

export default Landing