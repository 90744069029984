import React, { useState, useEffect} from 'react';
import {formatDistanceToNowStrict, isFuture } from 'date-fns'
import { Link, useRouteMatch} from 'react-router-dom'
import { ru } from 'date-fns/locale'
import {gtagEvent} from '../../../../lib/gtag'
import './Promo.scss'


export default ({item, isMobile, shopName}) => {

    const [promoDuration, setPromoDuration] = useState(0)
    const [tagsList, setTagsList] = useState()
    
    let { url } = useRouteMatch()
    const specialTags = ["Промо", "Акция" , "Скидка"]
    const {_id, title, discount, link, tags, picture, dueDate, itemType} = item

   
    useEffect(() => {
        const durationTime = (timeVal) => {
            if(typeof timeVal === 'undefined' && !timeVal){
                return ""
            }
            const endTime= new Date(timeVal)
            let time = formatDistanceToNowStrict(endTime,  
              {
                locale: ru,  
                addSuffix: true, 
                includeSeconds: false,
                roundingMethod: 'ceil'
              })
           return isFuture(endTime) ? `Истекает ${time}`: `Истекло ${time}`
        }
        setPromoDuration(durationTime(dueDate))
        const interval = setInterval(() => setPromoDuration(durationTime(dueDate)), 15000)
        return () => clearInterval(interval)
      }, [dueDate]); 


      useEffect(()=>{

        const filterTags = (tagsArr, mobile) =>{
            let tagsFiltered = []
            if (mobile){
                const tagsTmp = tagsArr.filter(el => el.title.length > 2)
                tagsFiltered = tagsTmp.slice(0, 2)
            }else{
                tagsFiltered = tagsArr.slice(0, 5)
            }
            return tagsFiltered
        }

          if (tags && tags.length>0){
            setTagsList(filterTags(tags, isMobile))
          }

      },[tags, isMobile])


      
      const trackClick = e => {
        const name = shopName.toLowerCase()
        // console.log(`${name} promo open`);
        gtagEvent({
          clientWindow: window,
          action: 'fasales_promo',
          category: `${name} promo open`,
          label: title,
        })
      }
    


    const promocodesIcon = itemType === "promocode" ?  
        <div className={`promocodes__item-icon-text${discount.label.length>11?"-sm":""}`}> -{discount.size}%<br></br>{discount.label.toLowerCase()}</div> : 
        <div className='promocodes__item-icon-img' style={{
             backgroundImage: `linear-gradient(90deg, rgba(255, 255, 255, 0) 67.71%, #F7FEFC 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), url(${picture})`
        }}></div>    

    
    const tagsListFiltered = tagsList && tagsList.map((el, id) => {
        const isSpecial = specialTags.includes(el.title)
        return <span className={`promocodes__item-labels-elem${isSpecial ? "-spec" : ""}`} key={id}>{el.title}</span>
      })
     
           
    return(    
         <>
             {isMobile 
              ? <a href={discount ? `${url}/code?coupon=${_id}` : `${link}`}  target="_blank" rel="noopener noreferrer" onClick={trackClick}>
                    <div className ="promocodes__item" >
                        <div className="promocodes__section-left">
                            <div className="promocodes__item-icon">
                            {promocodesIcon}
                            </div>
                        </div>
        
                        <div className="promocodes__section">
                            <div className="promocodes__section-top">
                                <div className="promocodes__item-labels"> 
                                    {tagsListFiltered }
                                </div>
                            </div>
                            <div className="promocodes__section-main">
                                <div className="promocodes__section-center">
                                    <div className="promocodes__item-title"> {title}</div>
                                    <div className="promocodes__item-time"> {promoDuration} </div> 
                                </div>
                            </div> 
                        </div>
                    </div>
                </a> 
            : <div className ='promocodes__item' >
                <div className="promocodes__section-left">
                    <div className="promocodes__item-icon">
                    {promocodesIcon}
                    </div>
                </div>

                <div className="promocodes__section">
                    <div className="promocodes__section-top">
                        <div className="promocodes__item-labels"> 
                            {tagsListFiltered}
                        </div>
                    </div>
                    <div className="promocodes__section-main">
                        <div className="promocodes__section-center">
                            <div className="promocodes__item-title"> {title}</div>
                            <div className="promocodes__item-time"> {promoDuration} </div> 
                        </div>
                        <div className="promocodes__section-right" >
                            {discount 
                                ? <Link to={`${url}/code?coupon=${_id}`}  target="_blank" rel="noopener noreferrer" onClick={trackClick}> 
                                    <div className="promocodes__item-code">Открыть промо</div>     
                                </Link>
                                : <a href={link} target="_blank" rel="noopener noreferrer" onClick={trackClick}> 
                                    <div className="promocodes__item-code">Открыть скидку</div> 
                                </a>}
                        </div>
                    </div> 
                </div>
            </div>}
         </>
    ) 
}