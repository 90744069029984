import React, { useState, useEffect } from 'react';
import { Link, Redirect,useParams,useLocation, useRouteMatch} from 'react-router-dom';
import Smooth from 'react-smooth';
import axios from 'axios'
import Promo from './Promo';
import Icon from '../../Icon';
import {TransitionGroup, CSSTransition } from 'react-transition-group'
import './Details.scss';
import { SpinnerCompact } from 'components';


export default ({ staticUri, apiUri, isMobile, liteAgent}) => {

  const [shopPromos, setShopPromos] = useState()
  const [filterSelected, setFilterSelected] = useState()
  const [sortKeys, setSortKeys] = useState()
  const [promoNum, setPromoNum] = useState([0, 0, 0])
  const [promoAviability, setPromoAviability] = useState([false, false, false, false])
  const [refresh, setRefresh] = useState(false)
  
  let { url } = useRouteMatch()
  let { name } = useParams()
  let location = useLocation()
  const promoUri = apiUri + name
  //["Лучшие", "Промокоды", "Акции", "Товары"]
  const filterLabels = ["Лучшие", "Промокоды", "Акции"]
  
  useEffect(()=>{
    location.state = name
  },[location, name])


  useEffect(() => {
    let mounted = true
    
    const countPromosNum = promo => {
      const promos = promo.filter(el => el.itemType === "promocode").length
      const sales = promo.filter(el => el.itemType === "sale").length
      const products = promo.filter(el => el.itemType === "product").length
      const arr = [promos, sales, products]
      setPromoNum([...arr]);
    }

    axios.get(promoUri)
      .then(res => {
        if (mounted) {
          setShopPromos(res.data.data.promo)
          countPromosNum(res.data.data.promo)
        }
      }).catch(err => {
        setShopPromos("error")
        console.error(err)
      })
      return () => mounted = false

  }, [promoUri])




  useEffect(() => {
    const defaultKeys = ['promocode', 'sale', 'product']

    const getSortingOrder = id =>
      ({
          0: 'best',
          1: 'promocode',
          2: 'sale',
          3: 'product'
      }[id] || 'promocode')

    const updateFilterKeys = (value, keys) => {
      let tmpKeys = keys && !keys.includes('best') ? 
        [...keys].filter(elem => elem !== value) : defaultKeys.filter(elem => elem !== value)
      tmpKeys = [value, ...tmpKeys]
      return tmpKeys
    }

    const sortFilter = ({ byId }) => {
      const type = getSortingOrder(byId)
      setSortKeys(keys => type!=='best' ? updateFilterKeys(type, keys) : [type]) 
    }

    sortFilter({
      byId: filterSelected,
    })
  }, [filterSelected]) 


  useEffect(() => {

    const sortByObject = data => data.reduce(
      (obj, elem, id) => ({
        ...obj,
        [elem]:id
    }), {} )

    const calculateWeight = arr => {  
      const weightArr = [...arr].map(el => el.weight)
      return weightArr.length !== 0 ? weightArr.reduce((total, amount) => total + amount) : 0
    }

    const customSort = ({ promos, sortField}) => {
      let sortedPromos
      if(sortKeys.includes('best')){
         sortedPromos = promos.sort((a, b) =>  calculateWeight(b.tags) - calculateWeight(a.tags))
      }else{
        const sortingObject = sortByObject(sortKeys)
        sortedPromos = promos.sort((a, b) => sortingObject[a[sortField]] - sortingObject[b[sortField]])
      }
      return sortedPromos
    }
  
    setShopPromos(prevProm => prevProm && [...customSort({
      promos: prevProm,
      sortField: 'itemType'
    })])
    
  }, [sortKeys])


  useEffect(() => {

    if(shopPromos){
      setRefresh(true)
    }
  }, [shopPromos])


  useEffect(() => {

      const getPromoNumber = id =>
        ({
            0: promoNum.reduce((a, b) => a + b),
            1: promoNum[0],
            2: promoNum[1],
            3: promoNum[2]
        }[id] || 0)
       
      let avblArr = [getPromoNumber(0)<=1]
      for(let i = 1; i <= 3; i++){
        avblArr.push(getPromoNumber(i) <=0 )
      }

      const selected = avblArr.indexOf(false)
      setFilterSelected(selected)
      setPromoAviability([...avblArr])
  }, [promoNum])





 const handleSelectedFilter = id => {
   if (filterSelected !== id){
        setRefresh(false)
        setFilterSelected(id)
   }
 }


const promocodes = refresh && shopPromos.map((item, id) => {
    return (
      <CSSTransition  key={id} timeout={liteAgent ? 300 : 500}  classNames={`promo-fade${liteAgent?'-lite':''}`}>
        <Promo key={id} item={item} isMobile={isMobile} shopName={name} />
      </CSSTransition>
    )
  })


const filters = filterLabels.map((item, id) => {
  return  <span key={id} className={`details__head-filter-value${promoAviability[id] ? "-none" : filterSelected === id ?  "-selected"  : ""} `} onClick={() => handleSelectedFilter(id)}>{item}</span>
})

 
  return shopPromos==='error' ?  (<Redirect exact to={url.replace(`/${name}`,'')} />) :
  (
    <Smooth from="0" to="1" attributeName="opacity" duration={500} easing="ease-in">
      <div className="details">
      

       <div className="details__head">
             <Link to="/promo"><img className="details__btn-back img-btn" src={staticUri + '/img/icons/arrow-back.svg'}
                alt="Назад" 
              /></Link>
            <div className="details__head-filter">
            
             {!isMobile &&<span className="details__head-filter-label">Сначала:</span>}            
                {filters}
            </div>
        </div>
      
        {!shopPromos ?
          <div className="promocodes">
            <SpinnerCompact />
          </div>
         :
        <TransitionGroup className="promocodes">
          { (promocodes.length!==0  ? 
            promocodes :
            <CSSTransition timeout={liteAgent ? 100: 300} classNames={`message-fade${liteAgent?'-lite':''}`}>
              <div className="msg__message">Ой-йой, похоже, здесь ничего нет</div> 
            </CSSTransition>
            )}
        </TransitionGroup>
        }

        {!isMobile && <div className="details__info">
            <div className="details__info-label">
                Больше предложений в наших социальных сетях
            </div>
            <div className="details__info-media">
                <a href="https://vk.com/fa_sales" target="_blank" rel="noopener noreferrer">
                 <Icon uri={staticUri} name="vk"  padding=" 0 .35rem" width="35px"  text="Вконтакте" textClass="details__info-media-item"/>
                </a>
                <a href="https://t.me/fa_sales" target="_blank" rel="noopener noreferrer">
                  <Icon uri={staticUri} name="telegram" padding="0 .35rem" width="35px"  text="Telegram" textClass="details__info-media-item"/> 
                </a>
                <a href="https://instagram.com/fasales_official" target="_blank" rel="noopener noreferrer">
                  <Icon uri={staticUri} name="instagram"  padding="0 .35rem" width="35px" text="Instagram" textClass="details__info-media-item"/>
                </a>
            </div>
          </div>}
      </div>
    </Smooth>
  )
}
