import React from 'react';
import './Page404.scss';

const Page404 = () => {
  return (
    <div id="p404">
      <div className="frame">
        <div className="message-box">
          <div className="message-box__message">Not found</div>
          <hr/>
          <div className="message-box__code">404</div>
        </div>
      </div>
    </div>
  )
}

export default Page404