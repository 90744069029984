import React, { useState, useEffect } from 'react'
import { Route, Switch, useRouteMatch, useLocation, Link, Redirect} from 'react-router-dom'
import Smooth from 'react-smooth'
import {CSSTransition } from 'react-transition-group'
import Grid from './Grid'
import Coupon from './Coupon'
import Details from './Details'
import './ShopList.scss'
import {gtagPath} from '../../lib/gtag'

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-TTGQEJZZS9'; 
//'G-7YRY5QRP2C'

export default ({ apiUri, staticUri }) => {

  const [mobile, setMobile]  = useState(window.innerWidth<960)
  const [phone, setPhone]  = useState(window.innerWidth<454)
  const [shopName, setShopName] = useState(null)
  const [liteAgent, setLiteAgent] = useState(false)
  const [safariAgent, setSafariAgent] = useState(false)
  const [mounted, setMounted] = useState(false)


  let location = useLocation()
  let { path, url} = useRouteMatch()

 useEffect(() => {
  const checkBrowser = () => {     
    const { userAgent } = window.navigator
    let chromeAgent =  userAgent.indexOf("Chrome") > -1; 
    let IExplorerAgent =  
     userAgent.indexOf("MSIE") > -1 ||  
     userAgent.indexOf("rv:") > -1; 
    let firefoxAgent = userAgent.indexOf("Firefox") > -1; 
    let safariAgent =  userAgent.indexOf("Safari") > -1; 
       
    if ((chromeAgent) && (safariAgent))  
        safariAgent = false; 

    let operaAgent = userAgent.indexOf("OP") > -1; 

    if ((chromeAgent) && (operaAgent))  
        chromeAgent = false;
         
    if (safariAgent||firefoxAgent||IExplorerAgent){
      setLiteAgent(true);
      if  (safariAgent)
        setSafariAgent(true)   
    }
  }

  checkBrowser()
  setMounted(true)
 },[])

 useEffect(() => {
  // console.log("Location",  location.pathname)
  if (location.pathname!=="/"){
    gtagPath({
      clientWindow: window,
      gaMeasurementId: GA_MEASUREMENT_ID, 
      title: location.pathname, 
      path: location.pathname,
    })
  }
   window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
   setShopName(location.state)
   return () =>  {
     setShopName(null)
   }
}, [location])


  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth<960)
      setPhone(window.innerWidth<454)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])


  // useEffect(() => {
  //   if(mobile && !location.pathname.match('/code')){
  //      handleFooterMouting(true)
  //   }

  //   handleFooterMouting(false)
  // }, [handleFooterMouting, mobile, location.pathname])


 return (
    <Smooth from="0" to="1" attributeName="opacity" duration={ 300 } easing="ease-in">
      <div className="frame promo noselect" style ={mobile && location.pathname.match('/code') && {paddingBottom: 0}}>
        <img className="emoji-badge" src={staticUri + '/img/emoji/robot-face.png'} alt="emoji" />

        <CSSTransition  
          in={mounted}
          classNames={`title-fade${liteAgent?'-lite':''}`}
          timeout={liteAgent===true ? 100 : 500}
        >
      
          <div className="msg">
           <Link to={url}><img className={`msg__badge ${liteAgent?'':'msg__badge-shadow'} ${shopName ? "msg__badge-animate" : ""}`} src={staticUri + '/img/emoji/money-mouth-face.png'} alt="emoji"/> </Link>     
            <div className="msg__text">
                <Link to={url}>
                  <h1 className="msg__text--primary">
                    Промокоды и акции {phone &&<br/>}{shopName && <q>{shopName}</q>}
                  </h1>
                </Link>           
                <p className="msg__text--secondary">Экономим вместе</p>
            </div>
          </div>
        </CSSTransition>

        <Switch>    
            <Route exact path={`${path}`} render={() => <Grid apiUri={apiUri + '/shops/'} />}/>
            <Route exact path={`${path}/:name`} render={() => <Details staticUri={staticUri} apiUri={apiUri + '/shops/'} isMobile={mobile} liteAgent={liteAgent} />}/>
            <Route exact path={`${path}/:name/code`} render={() => <Coupon staticUri={staticUri} apiUri={apiUri + '/shops/'} safari={safariAgent} liteAgent={liteAgent}  isMobile={mobile}  /> }/>
            <Redirect to={`${path}`}/>
        </Switch>      
      </div>   
    </Smooth>
  )
}