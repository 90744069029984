import React, {useState,useEffect} from 'react';
import {  useLocation} from 'react-router-dom'
import { Icon } from '..';
import './Footer.scss';


const Footer = ({ staticUri, isSet}) => {

  const [visible, setVisible]= useState(true)
  let location = useLocation()

  useEffect(()=>{
    setVisible(location.pathname!=='/landing')
  },[location])


  useEffect(()=>{
    setVisible(isSet)
  },[isSet])


  return visible ?
   (
    <div id="footer" className="footer text-light">
      <div className="footer__item">
        <ul className="footer__item-links">
          <li><a href="https://vk.com/fa_sales" target="_blank" rel="noopener noreferrer"><Icon uri={staticUri} name="vk" padding="0 0 .25rem" width="1.6rem" invert=".625"/></a></li>
          <li><a href="https://t.me/fa_sales" target="_blank" rel="noopener noreferrer"><Icon uri={staticUri} name="telegram" padding="0 0 .6rem" width="1.5rem" invert=".625"/></a></li>
          <li><a href="https://instagram.com/fasales_official" target="_blank" rel="noopener noreferrer"><Icon uri={staticUri} name="instagram" width="1.4rem" invert=".625"/></a></li>
        </ul>

      </div>
      <div className="footer__item footer__item--right">
        <div className="footer__item-cookies">
          Мы используем куки.
        </div><br/>
        <div className="footer__item--right__copyright">
          <nobr>/fa/ sales © 2021</nobr>
        </div>
      </div>
    </div>
  )
  : null
}

export default Footer
