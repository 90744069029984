import React, {useEffect, useState} from 'react';
import axios from 'axios'
import Smooth from 'react-smooth';
import { Link, useRouteMatch } from 'react-router-dom';
import { Spinner } from 'components'
import {gtagEvent} from '../../../lib/gtag'
import './Grid.scss';

export default ({ apiUri}) => {

  const [shopList, setShopList] = useState()
  let { url } = useRouteMatch()

  useEffect(() => {
    let mounted = true
    axios.get(apiUri)
      .then(res => mounted && setShopList(res.data.data))
      .catch(err => console.error(err))
    return () => mounted = false
  }, [apiUri])



  const trackClick = (e, label) => {
    gtagEvent({
      clientWindow: window,
      action: 'fasales_shop',
      category: 'shop open',
      label: label,
    })
  }


  const getPromosGet = num => 
     ({
      1: "1 акция",
      2: `${num} акции`,
      3: `${num} акции`,
      4: `${num} акции`
  }[num] || `${num} акций`)


   

const shops = shopList ? shopList.map((item, index) => { return (
  <div className="card" key={index}> 
    <div className="card__thumb" style={ { backgroundImage: `url(${ item.logo })` } }></div>
    <center>
      <hr className="hr-light"/>
      <h2 className="card__name">{ item.name }</h2>
      <div className="card__codes">{ item.promoCount===0 ? <br/> : getPromosGet(item.promoCount)}</div>

      {/* <a href={`${window.location.href}/${item.name}`} target="_self" onClick={e => trackClick(e, item.name)}> <button className="card__btn btn" >Смотреть</button></a>  */}
      <Link to={`${url}/${item.name}`} onClick={e => trackClick(e, item.name)}> <button className="card__btn btn" >Смотреть</button></Link> 
    </center>
  </div>
) }) : <Spinner/>

 return (
    <Smooth from="0" to="1" attributeName="opacity" duration={ 300 } easing="ease-in">
      <div className="grid">
          {shops}
      </div>
    </Smooth>
  )
}