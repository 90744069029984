import React, { useState } from 'react'
import { SpinnerCompact } from '..';

export default ({ src, alt, className, onClick, onError, duration }) => {
  const [srcState, setSrc] = useState()

  return (
    <>
      <img className={className} src={src}
        alt={ alt }
        style={{
          opacity: srcState === src ? 1 : 0,
          // minHeight: srcState === src ? 'auto' : '10rem',
          transition: `opacity ${duration}s`
        }}
        onClick={onClick}
        onLoad={e => setSrc(e.target.src)}
        onError={onError}
        />
      { srcState === src ? '' : <SpinnerCompact /> }
    </>
  )

}
