import React from 'react';
import './Spinner.scss';
import Smooth from 'react-smooth';

const Spinner = () => {
  return (
    <Smooth from="0" to="1" attributeName="opacity" duration={250} easing="ease-in" begin={400} >
      <div id="spinner" className="spinner">
        <div className="loadingio-spinner-ripple-mryceesfvs">
          <div className="ldio">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </Smooth>
  )
}

export default Spinner