import React, {useState, useEffect} from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import './Navbar.scss';

const Navbar = ({staticUri}) => {

  const [promoRedirect, setPromoRedirect] = useState(false)

  let location = useLocation()

  useEffect(() => {
    // console.log("NAVBAR",  location.pathname)
    setPromoRedirect(location.pathname==='/landing')
  }, [location])

  return (
    <div id="navbar" className="navbar">

      {/* <NavLink to="/" className="navbar__brand-name">Фасейлс</NavLink>
      <div className="navbar__links">
        <NavLink activeClassName="navbar__links--active" to="/promo">Промоакции</NavLink>
        <NavLink activeClassName="navbar__links--active" to="/quiz">Викторина</NavLink>
      </div> */}

      <NavLink to={promoRedirect ? '/promo' : '/landing'} className="navbar__logo">
        <img className="navbar__logo-img" src={`${staticUri}/img/logo_fasales.svg`} alt="fasales_logo"/>
      </NavLink>

    </div>
  )
}

export default Navbar