import React, {useState, useEffect} from 'react';
import './LandingItem.scss';
import Icon from '../../Icon';
import Smooth from 'react-smooth';



const LandingItem = ({id, staticUri, vk, telegram, icon, title, mediaHandler, open}) => {

  const [openMedia, setOpenMedia] = useState(false)

  useEffect(() => {
    if ((id !== open) && document.body.clientWidth <= 1024) {
      setOpenMedia(false)
    }
}, [open, id])

  const showMediaLinks = () => {    
      setOpenMedia(prev => !prev)
      mediaHandler(id)
  }


  return (  
    <div className={`landing-box__media-item ${openMedia?"opened":""}`} onClick={() => showMediaLinks()}>                
        <Icon uri={staticUri} name={icon}  padding=" 0 .35rem" text={title} textClass="landing-box__media-item-text"/>             
        { openMedia && <div className={`landing-box__media-item-links`}>
            <Smooth from="0" to="1" attributeName="opacity" duration={500} easing="ease-in">
              <span>
              <a href={vk} target="_blank" rel="noopener noreferrer"><span>VK</span></a> 
              <a href={telegram} target="_blank" rel="noopener noreferrer"><span>Telegram</span></a>
              </span>
            </Smooth>
          </div> } 
    </div>
             
  )
}

export default LandingItem