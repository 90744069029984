import React, { useState, useEffect } from 'react';
import './Form.scss';

const Form = ({ onSubmit }) => {
  const [inputData, setInputData] = useState({fullName: '', email: '', phone: '', terms: false});
  // const [isBlured, setBlur] = useState({});
  const [isFormValid, setFormValid] = useState({
    formValid: false,
    fullName: false,
    email: false,
    phone: false,
    terms: false
  });

  useEffect(() => {
    const validateForm = () => {
      const fullNameReg=/^[a-zа-яё\s-]{5,64}$/i;
      const emailReg=/^([а-яё\w.%+-]+)@([а-яё\w-]+\.)+([a-zа-яё]{2,})$/i;
      const phoneReg=/^[\d\s()+-]{4,31}$/;
      // const phoneReg=/^\d{4,16}$/;
  
      const fullNameValid = (inputData.fullName && fullNameReg.test(inputData.fullName)) || false;
      const emailValid = (inputData.email && emailReg.test(inputData.email)) || false;
      const phoneValid = (inputData.phone && phoneReg.test(inputData.phone)) || false;
      const formValid = (fullNameValid && emailValid && phoneValid && inputData.terms) || false;
  
      setFormValid({ 
        formValid: formValid,
        fullName: fullNameValid,
        email: emailValid,
        phone: phoneValid
      });
    };
    
    validateForm();
  }, [inputData]);

  const handleFormInput = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;    
    setInputData({...inputData, [e.target.name]: value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(isFormValid.formValid) {
      e.target.disabled = true;
      delete inputData.terms;
      onSubmit(inputData);
    }
  };

  const handleFocus = target => {
    target.className = 'form__input';
  };

  const handleBlur = (target) => {
    if(target.value && !isFormValid[target.name]) target.className += ' form__input_invalid'
    // setBlur({...isBlured, [name]: true});
  };

  // const inputClassName = (name) => {
  //   let className = 'form__input';
  //   if(isBlured[name] && !isFormValid[name]) className += ' form__input_invalid'
  //   return className;
  // };

  return (
    <div className="form">
      <form>
        <label className="form__label" htmlFor="fullName">ФИО полностью (русский/латиница)</label>
        <input id="fullName" className='form__input' name="fullName" type="text" placeholder="Иванов Иван Иванович" pattern="^[a-zA-Zа-яА-ЯёЁ\s-]{5,64}$" required
          value={inputData.fullName} onChange={e => handleFormInput(e)} onBlur={e => handleBlur(e.target)} onFocus={e => handleFocus(e.target)} />

        <label className="form__label" htmlFor="email">Адрес электронной почты</label>
        <input id="email" className='form__input' name="email" type="email" placeholder="fasales@gmail.com" 
          pattern="^([а-яА-ЯёЁ\w.%+-]+)@([а-яА-ЯёЁ\w-]+\.)+([[a-zA-Zа-яА-ЯёЁ]{2,})$" required 
          value={inputData.email} onChange={e => handleFormInput(e)} onBlur={e => handleBlur(e.target)} onFocus={e => handleFocus(e.target)} />

        <label className="form__label" htmlFor="phone">Номер мобильного</label>
        <input id="phone" className='form__input' name="phone" type="tel" placeholder="7 XXX XXXXXXX" pattern="^[\d\s()+-]{4,31}$" required 
          value={inputData.phone} onChange={e => handleFormInput(e)} onBlur={e => handleBlur(e.target)} onFocus={e => handleFocus(e.target)} />

        <div className="form__terms">
          <input id="terms" className="form__terms-checkbox" name="terms" type="checkbox" required onChange={e => handleFormInput(e)} />
          <label className="form__terms-label form__terms-label-for-checkbox" htmlFor="terms">Я согласен на обработку персональных данных.</label>
        </div>
        <button className="btn form__submit-btn" type="submit" onClick={e => handleSubmit(e)} disabled={!isFormValid.formValid} >Отправить</button>
      </form>
      <div className="form__description">
        <p className="text-light text-sm">Все поля обязательны к заполнению. Проверьте указанные данные на достоверность, ведь только так мы сможем с вами связаться.</p>
      </div>
    </div>
  )
}

export default Form;