import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import axios from 'axios';
import Smooth from 'react-smooth';
import Markdown from 'markdown-to-jsx';
import { Spinner, Result, Form, SmoothImage } from '..';
import './Quiz.scss';

const Quiz = props => {
  const [quiz, setQuiz] = useState({questions:[], result: {picture:'', text: []}});
  const [stats, setStats] = useState(null);
  const [isFinished, setFinished] = useState(localStorage.getItem('finished') === '1' ? true : false);
  const [isStarted, setStarted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [curQuestionIndex, setCurQuestionIndex] = useState(0);
  const [score, setScore] = useState(localStorage.getItem('score') || 0);
  // const quizName = props.match.params.name;
  const quizName = 'asos';

  useEffect(() => {
    axios.get(props.apiUri + '/quiz/get?name=' + quizName).then(async ({data}) => {
      setQuiz(data[0]);
    })
    axios.get(props.apiUri + '/quiz/stats/get?name=' + quizName).then((res) => {
      setStats(res.data[0].questions);
    })
  }, [props.apiUri, quizName]);

  const questions = quiz.questions;
  const result = quiz.result;
  let curQuestion = questions[curQuestionIndex];

  const handleChose = index => {
    if(selectedOption == null) {
      setSelectedOption(index);
      // setUserAnswers([...userAnswers, index])
      if(index === curQuestion.correctOpt) setScore(score + 1);
    }
  };

  const handleNextQuestion = () => {
    setSelectedOption(null);
    if(selectedOption === null) return 0;
    if (curQuestionIndex < questions.length - 1) {
      setCurQuestionIndex(curQuestionIndex + 1)
    }
    else {
      localStorage.setItem('finished', 1);
      localStorage.setItem('score', score);
      setFinished(true);
      window.scrollTo(0, 0);
    };
  };

  if (!quiz.questions.length || !stats ) { return <Spinner /> }
  else return(
    <Smooth from="0" to="1" attributeName="opacity" duration={300} easing="ease-in" >
      {isFinished ? (     
        <div className="quiz frame">
          <Result apiUri={props.apiUri} staticUri={props.staticUri} questionsCount={questions.length} score={score} result={result} />
          { localStorage.getItem('submitted') !== '1' ? (<Form onSubmit={props.onSubmit} />) : (<div><br/><br/></div>) }
        </div>
      ) :
      isStarted ? (
        <div className="quiz frame">
          <div className="quiz__question">
            <div className="quiz__question-counter">{curQuestionIndex + 1 + '/' + questions.length}</div>
            <div className="quiz__question-picture-box picture-box">
              <SmoothImage className="picture-box__picture text-semi-light" duration=".25"
                src={props.staticUri+curQuestion.picture} onError={()=> handleChose(curQuestion.correctOpt) }
                alt="Картинка потерялась 😔" >
              </SmoothImage>
            </div>
            <h3 className="quiz__question-text">{curQuestion.text}</h3>
          </div>
          <div className="quiz__answer">
              <ul className="quiz__answer-options noselect">
                {curQuestion.options.map((item, index) => {
                  const isCorrect = index === curQuestion.correctOpt;
                  let percent;
                  if(stats !== null) {
                    const curStats = stats[curQuestionIndex];
                    const answerCounter = curStats.answerCounter;
                    const optionsCounter = curStats.options[index];
                    percent = (100 / (answerCounter / optionsCounter)).toFixed(1);
                  }   
                  else percent = null; 
                  
                  return <li 
                    key={index}
                    className={classnames("quiz__answer-options-btn",
                    {"quiz__answer-options-btn_disabled": selectedOption !== null},
                    {"quiz__answer-options-btn_wrong": selectedOption !== null && selectedOption === index && !isCorrect},
                    {"quiz__answer-options-btn_correct": selectedOption !== null && isCorrect})}
                    onClick={() => handleChose(index)}>
                      <div className="quiz__answer-options-btn-text">
                        {/* Дай Бог здоровья */}                        { 
                          index === selectedOption ?
                            [(index === curQuestion.correctOpt ? curQuestion.answer.text.correct: curQuestion.answer.text.wrong),
                              ' ', curQuestion.answer.link &&
                              <a key={index} className="quiz__answer-options-link"
                                 href={curQuestion.answer.link.uri} target="_blank" rel="noopener noreferrer">
                              {curQuestion.answer.link.text}</a>
                            ]
                          : item
                        }
                      </div>
                  {selectedOption !== null && <div className="quiz__answer-options-btn-stats">{percent >= 0 && percent + '%'}</div>}
                  </li>
                })}
              </ul>
              <button 
                className={classnames("btn", selectedOption === null ? "quiz__answer-btn_hidden" : "quiz__answer-btn")}
                onClick={handleNextQuestion}>{curQuestionIndex === questions.length - 1 ? 'Результат' : 'Дальше'}
              </button>
            </div>
        </div>
      ) :
      (
        <div className="about frame">
          <h1>{quiz.title}</h1><hr/>
          <Smooth from="0" to="1" duration={700} attributeName="opacity">
            <img className="nopointer about__logo" src={props.staticUri+quiz.picture} alt="/fa/ sales" />
          </Smooth><hr/>
          {/* <p dangerouslySetInnerHTML={{__html: quiz.description}}></p> */}
          <p><Markdown>{quiz.description}</Markdown></p>
          {/* <div className="about__addition-info">
            <p className="text-sm text-semi-light">
              У меня болит зуб.<br />
              До свидания!
            </p>
          </div> */}
          <br/>
          <button className="btn" onClick={() => setStarted(true)}>Пройти тест</button>
        </div>
      )}
    </Smooth>
  );

}

export default Quiz;